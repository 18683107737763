@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-slide-up {
    animation: slide-up 0.9s ease-out;
  }
  
  .animate-nav-up {
    animation: slide-up 0.2s ease-out;
    -webkit-animation: slide-up 0.2s ease-out;
}
  .half-top-border {
    position: relative;
  }
  
  .half-top-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%; 
    height: 8px; 
    background-color: #5f3afc; 
  }

  .custom-color {
    color: #5f3afc;
  }

  /* Services Card Hover*/
.hover-card {
  position: relative;
  overflow: hidden;
}

.border-line-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #5f3afc;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.hover-card:hover .border-line-container::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Featured Property */
.group:hover .absolute {
  opacity: 1;
}

/* border  */
.half-top-border1 {
  position: relative;
}

.half-top-border1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; 
  height: 8px; 
  background-color: white; 
}
